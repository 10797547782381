import axios from "axios";
import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider";
import { somethingWentWrongDialog } from "./Components/dialog";

axios.interceptors.request.use(
    async (config) => {
        let ExpiresIn = localStorage.getItem("ExpiresIn");
        if (ExpiresIn) {
            let time = parseInt(ExpiresIn); //milli seconds
            let now = new Date().getTime();
            let setupTime = localStorage.getItem("setupTime");
            if (setupTime == null) {
                localStorage.setItem("setupTime", now);
            } else {
                if (now - setupTime > time * 1000) {
                    localStorage.clear();
                } else if (time - (now - setupTime) / 1000 < 300) {
                    try {
                        const cognitoClient = new CognitoIdentityProviderClient(
                            {
                                region: process.env.REACT_APP_REGION,
                            }
                        );
                        const refreshAuthCommand = new InitiateAuthCommand({
                            AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
                            ClientId: process.env.REACT_APP_CLIENT_ID,
                            AuthParameters: {
                                REFRESH_TOKEN:
                                    localStorage.getItem("RefreshToken"),
                            },
                        });
                        const refreshResponse = await cognitoClient.send(
                            refreshAuthCommand
                        );
                        localStorage.setItem(
                            "IdToken",
                            refreshResponse.AuthenticationResult?.IdToken
                        );
                        let now = new Date().getTime();
                        localStorage.setItem("setupTime", now);
                    } catch (error) {
                        localStorage.clear();
                        somethingWentWrongDialog();
                    }
                }
            }
        }
        // setting updated token
        return config;
    },
    (err) => {}
);

const getList = (page) => {
    return axios
        .options(`${process.env.REACT_APP_BACKEND_HOST}/customers`)
        .then((res) => {
            return axios.get(
                `${process.env.REACT_APP_BACKEND_HOST}/customers?page=${page}&page_size=10`,
                {
                    headers: {
                        Authorization: localStorage.getItem("IdToken"),
                    },
                }
            );
        })
        .catch((err) => somethingWentWrongDialog());
};

const addCustomer = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            let addCustomerRes = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST}/customers`,
                {
                    bank: "",
                    customer_number: "",
                    bank_account_number: formData.bank_account_number,
                    birthday: new Date(formData.birthday).toISOString(),
                    city: formData.city,
                    email: formData.email,
                    first_name: formData.first_name,
                    gender: formData.gender,
                    identification_expiry_date: new Date(
                        formData.identification_expiry_date
                    ).toISOString(),
                    identification_hand_out_country:
                        formData.identification_hand_out_country,
                    identification_number: formData.identification_number,
                    identification_origin: formData.identification_origin,
                    identification_type: formData.identification_type,
                    initials: formData.initials,
                    last_name: formData.last_name,
                    last_name_affix: formData.last_name_affix,
                    name_on_bank_account: formData.name_on_bank_account,
                    phone_number: formData.phone_number,
                    postal_code: formData.postal_code,
                    street: formData.street,
                    street_number: formData.street_number,
                    street_number_add_on: formData.street_number_add_on,
                },
                {
                    headers: {
                        Authorization: localStorage.getItem("IdToken"),
                    },
                }
            );
            resolve(addCustomerRes);
        } catch (error) {
            somethingWentWrongDialog();
        }
    });
};

const editCustomer = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            let addCustomerRes = await axios.put(
                `${process.env.REACT_APP_BACKEND_HOST}/customers/${formData.id}`,
                {
                    bank: "",
                    customer_number: "",
                    bank_account_number: formData.bank_account_number,
                    birthday: new Date(formData.birthday).toISOString(),
                    city: formData.city,
                    email: formData.email,
                    first_name: formData.first_name,
                    gender: formData.gender,
                    identification_expiry_date: new Date(
                        formData.identification_expiry_date
                    ).toISOString(),
                    identification_hand_out_country:
                        formData.identification_hand_out_country,
                    identification_number: formData.identification_number,
                    identification_origin: formData.identification_origin,
                    identification_type: formData.identification_type,
                    initials: formData.initials,
                    last_name: formData.last_name,
                    last_name_affix: formData.last_name_affix,
                    name_on_bank_account: formData.name_on_bank_account,
                    phone_number: formData.phone_number,
                    postal_code: formData.postal_code,
                    street: formData.street,
                    street_number: formData.street_number,
                    street_number_add_on: formData.street_number_add_on,
                },
                {
                    headers: {
                        Authorization: localStorage.getItem("IdToken"),
                    },
                }
            );
            resolve(addCustomerRes);
        } catch (error) {
            somethingWentWrongDialog();
        }
    });
};

export { getList, addCustomer, editCustomer };
