function reformatDate(dateString) {
    let date = new Date(Date.parse(dateString));
    var newDateString = "";
    if (date.getDate() < 10) {
        newDateString += `0`;
    }
    newDateString += `${date.getDate()}-`;
    if (date.getMonth() < 9) {
        newDateString += `0`;
    }
    newDateString += `${date.getMonth() + 1}-${date.getFullYear()}`;
    return newDateString;
}

export default reformatDate;
