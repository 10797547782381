import Swal from "sweetalert2";

export async function somethingWentWrongDialog() {
    return Swal.fire({
        padding: "40px",
        text: "Sorry, er is een fout opgetreden!",
        confirmButtonColor: "#2994D1",
    });
}

export async function confirmationDialog(title, text) {
    return Swal.fire({
        padding: "40px",
        title: title,
        text: text,
        confirmButtonText: "Ja",
        denyButtonText: "Nee",
        confirmButtonColor: "#2994D1",
        denyButtonColor: "#FF0000BB",
        showDenyButton: true,
    });
}
