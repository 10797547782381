let identificationTypes = [
    "Paspoort",
    "Rijbewijs",
    "Europese ID-Kaart",
    "Verblijfsdocument Type I",
    "Verblijfsdocument Type II",
    "Verblijfsdocument Type III",
    "Verblijfsdocument Type IV",
    "Verblijfsdocument Type V",
    "Verblijfsdocument Type EU/EER",
    "IND Sticker",
    "Geprivilegieerdendocument",
    "Document permanente verblijfsvergunning",
];

let countries = [
    "Afghanistan",
    "Aland",
    "Albanië",
    "Algerije",
    "Amerikaanse Maagdeneilanden Amerikaans-Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua en Barbuda Argentinië",
    "Armenië Aruba Australië Azerbeidzjan Bahama’s Bahrein Bangladesh Barbados België",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba Bosnië en Herzegovina Botswana",
    "Brazilië",
    "Brits Territorium in de Indische Oceaan Britse Maagdeneilanden",
    "Brunei",
    "Bulgarije",
    "Burkina Faso",
    "Burundi",
    "Cambodja",
    "Canada",
    "Centraal-Afrikaanse Republiek",
    "Chili",
    "China",
    "Christmaseiland",
    "Cocoseilanden",
    "Colombia",
    "Comoren",
    "Congo",
    "Congo-Brazzaville",
    "Cookeilanden",
    "Costa Rica",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Denemarken",
    "Djibouti",
    "Dominica",
    "Dominicaanse Republiek Duitsland",
    "Ecuador",
    "Egypte",
    "Salvador Equatoriaal-Guinea Eritrea",
    "Estland",
    "Ethiopië",
    "Faeröer Falklandeilanden Fiji",
    "Filipijnen",
    "Finland",
    "Frankrijk",
    "Franse Zuidelijke en Antarctische Gebieden Frans-Guyana",
    "Frans-Polynesië Gabon",
    "Gambia Georgië",
    "Ghana Gibraltar Grenada Griekenland Groenland Guadeloupe Guam Guatemala Guernsey Guinee Guinee-Bissau Guyana",
    "Heard en McDonaldeilanden Honduras",
    "Hongarije",
    "Hongkong",
    "Ierland IJsland India Indonesië Irak",
    "Iran",
    "Isle of Man Israël",
    "Italië Ivoorkust Jamaica Japan",
    "Jemen",
    "Jersey",
    "Jordanië Kaaimaneilanden Kaapverdië Kameroen Kazachstan Kenia",
    "Kirgizië",
    "Kiribati",
    "Kleine Pacifische eilanden van de Verenigde Staten Koeweit",
    "Kroatië",
    "Laos",
    "Lesotho",
    "Letland",
    "Libanon",
    "Liberia",
    "Libië",
    "Liechtenstein",
    "Litouwen",
    "Luxemburg",
    "Macau",
    "Macedoniē",
    "Madagaskar",
    "Malawi",
    "Maldiven",
    "Maleisië",
    "Mali",
    "Malta",
    "Marokko",
    "Marshalleilanden",
    "Martinique",
    "Mauritanië",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldavië",
    "Monaco",
    "Mongolië",
    "Montenegro",
    "Montserrat",
    "Mozambique",
    "Myanmar",
    "Namibië",
    "Nauru",
    "Nederland",
    "Nederlandse Antillen",
    "Nepal",
    "Nicaragua",
    "Nieuw-Caledonië",
    "Nieuw-Zeeland",
    "Niger",
    "Nigeria",
    "Niue",
    "Noordelijke Marianen Noord-Korea Noorwegen",
    "Norfolk",
    "Oekraïne",
    "Oezbekistan",
    "Oman",
    "Oostenrijk",
    "Oost-Timor",
    "Pakistan",
    "Palau",
    "Palestijnse Autoriteit Panama Papoea-Nieuw-Guinea Paraguay",
    "Peru",
    "Pitcairneilanden",
    "Polen",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Roemenië",
    "Rusland",
    "Rwanda",
    "Saint Kitts en Nevis",
    "Saint Lucia",
    "Saint Vincent en de Grenadines Saint-Barthélemy",
    "Saint-Pierre en Miquelon Salomonseilanden",
    "Samoa",
    "San Marino",
    "Sao Tomé en Principe Saoedi-Arabië",
    "Senegal",
    "Servië",
    "Seychellen",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Sint Maarten (Dutch part) Sint-Helena",
    "Slovenië",
    "Slowakije",
    "Soedan",
    "Somalië",
    "South Sudan",
    "Spanje",
    "Spitsbergen en Jan Mayen",
    "Sri Lanka",
    "Suriname",
    "Swaziland",
    "Syrië",
    "Tadzjikistan",
    "Taiwan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tokelau-eilanden",
    "Tonga",
    "Trinidad en Tobago",
    "Tsjaad",
    "Tsjechië",
    "Tunesië",
    "Turkije",
    "Turkmenistan",
    "Turks- en Caicoseilanden",
    "Tuvalu",
    "Uganda",
    "Uruguay",
    "Vanuatu",
    "Vaticaanstad",
    "Venezuela",
    "Verenigd Koninkrijk",
    "Verenigde Arabische Emiraten",
    "Verenigde Staten",
    "Vietnam",
    "Wallis en Futuna",
    "Westelijke Sahara",
    "Wit-Rusland",
    "Zambia",
    "Zimbabwe",
    "Zuid-Afrika",
    "Zuid-Georgië en de Zuidelijke Sandwicheilanden Zuid-Korea",
    "Zweden",
    "Zwitserland",
];

export { identificationTypes, countries };
