import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../App";
import "./Login.css";

const Login = () => {
    const [toggle, setToggle] = useState(false);
    const { state, dispatch } = useContext(store);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("IdToken")) {
            navigate("customers");
        }
    }, [navigate]);

    return (
        <>
            <div className="header">
                {state.loading ? (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 999,
                            backgroundColor: "rgb(0,0,0,0.2)",
                        }}
                    >
                        <div className="spinner-border"></div>
                    </div>
                ) : null}
                <div className="logo">
                    <img src={require("../assets/img/logo.png")} alt="" />
                </div>
            </div>
            <div className="login">
                <h1>Login</h1>
                <div className="underline">
                    <div
                        className="uline"
                        style={{ backgroundColor: "#F5A34D" }}
                    ></div>
                    <div
                        className="uline"
                        style={{ backgroundColor: "#2994D1" }}
                    ></div>
                    <div
                        className="uline"
                        style={{ backgroundColor: "#E84A80" }}
                    ></div>
                </div>
                <form
                    className="login-input"
                    onSubmit={(e) => {
                        e.preventDefault();
                        dispatch({ type: "OnSubmit" });
                    }}
                    autoComplete="false"
                >
                    <div className="input-icon-wrap">
                        <span className="input-icon">
                            <img
                                src={require("../assets/img/Messages.png")}
                                alt=""
                                height="21px"
                                width="22px"
                            />
                        </span>
                        <input
                            autoComplete="false"
                            type="text"
                            required={"required"}
                            value={state.username}
                            className="input-with-icon"
                            id="form-name"
                            placeholder="sammychung@peoples.nl"
                            onChange={(e) =>
                                dispatch({
                                    type: "UpdateUserName",
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="input-icon-wrap2">
                        <span className="input-icon">
                            <img
                                src={require("../assets/img/Lock.png")}
                                alt=""
                                height="20px"
                                width="22px"
                            />
                        </span>
                        <input
                            autoComplete="false"
                            type={toggle ? "text" : "password"}
                            required={"required"}
                            value={state.password}
                            className="input-with-icon2"
                            id="form-name"
                            onChange={(e) =>
                                dispatch({
                                    type: "UpdatePassword",
                                    payload: e.target.value,
                                })
                            }
                        />
                        <span
                            className="input-icon2"
                            onClick={() => setToggle(!toggle)}
                        >
                            <img
                                src={
                                    toggle
                                        ? require("../assets/img/seen.png")
                                        : require("../assets/img/unseen.png")
                                }
                                alt=""
                            />
                        </span>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-center mt-5">
                        <button className="btn" type="submit">
                            Inloggen
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;
