import React from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { confirmationDialog } from "../Components/dialog";

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  let logOut = () => {
    confirmationDialog("", "Weet je zeker dat je wilt uitloggen?").then(res => {
      console.log(res);
      if (res.isConfirmed) {
        localStorage.setItem("IdToken", "");
        localStorage.setItem("RefreshToken", "");
        localStorage.setItem("AccessToken", "");
        localStorage.setItem("ExpiresIn", "");
        localStorage.setItem("Username", "");
        localStorage.setItem("FullName", "");
        localStorage.setItem("setupTime", "");
        navigate("/");
      }
    });
  };
  return (
    <div id="main" className="main-bar">
      <div className="top-icon">
        <div>
          <img src={require("../assets/img/logo2.png")} alt="" />
        </div>
        <div>
          <img
            src={require("../assets/img/Menu.png")}
            alt=""
            onClick={toggleSidebar}
          />
        </div>
      </div>
      <div className="bottom">
        <ul>
          <h6>Klantenoverzicht</h6>
        </ul>
      </div>
      <button className="logout" onClick={logOut}>
        Log Out
      </button>
      <div className="copyright">peoples backoffice © 2022</div>
    </div>
  );
};

export default Navbar;
