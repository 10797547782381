import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { editCustomer } from "../service";
import { identificationTypes, countries } from "./Values";
import "./Form.css";
import {
    confirmationDialog,
    somethingWentWrongDialog,
} from "../Components/dialog";

const getOriginalValues = (data) => {
    return {
        id: data.id,
        last_name: data.last_name,
        last_name_affix: data.last_name_affix,
        gender: data.gender,
        first_name: data.first_name,
        initials: data.initials,
        birthday: data.birthday,
        street: data?.address?.street,
        city: data?.address?.city,
        street_number: data?.address?.street_number,
        street_number_add_on: data?.address?.street_number_add_on,
        postal_code: data?.address?.postal_code,
        phone_number: data.phone_number,
        email: data.email,
        bank_account_number: data?.bank?.bank_account_number,
        name_on_bank_account: data?.bank?.name_on_bank_account,
        identification_type: data?.identity?.identification_type,
        identification_number: data?.identity?.identification_number,
        identification_expiry_date: data?.identity?.identification_expiry_date,
        identification_hand_out_country:
            data?.identity?.identification_hand_out_country,
        identification_origin: data?.identity?.identification_origin,
    };
};

const EditForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setForm] = useState(getOriginalValues(location.state));

    const handleChange = (e) => {
        setForm({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        confirmationDialog(
            "",
            "Weet je zeker dat je de wijzigingen wilt opslaan?"
        ).then((res) => {
            console.log(res);
            if (res.isConfirmed)
                editCustomer(formData)
                    .then((res) => {
                        if (!res.data) {
                            navigate("/customers");
                        }
                    })
                    .catch((err) => somethingWentWrongDialog());
        });
    };
    return (
        <>
            <Header />
            <form className="form" id="form" onSubmit={handleSubmit}>
                <div className="form-heading">
                    <span>
                        <i
                            className="fa-solid fa-arrow-left"
                            onClick={() => navigate("/customers")}
                        ></i>
                    </span>
                    <h1>
                        Klantenoverzicht{" "}
                        <span>
                            <i className="fa-solid fa-chevron-right"></i>
                        </span>{" "}
                        Klant bewerken
                    </h1>
                </div>

                <hr style={{ marginTop: "60px", marginBottom: "0px" }} />

                <div className="form-elements">
                    <div className="form-ele-1">
                        <h4 className="heading">Persoonsgegevens</h4>
                        <div className="input-1">
                            <select
                                id="Selector"
                                required
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                            >
                                <option value="">*Selecteer geslacht</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <input
                                required
                                name="initials"
                                value={formData.initials}
                                onChange={handleChange}
                                placeholder="*Voorletters"
                            />
                            <input
                                required
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                placeholder="*Voornaam"
                            />
                        </div>
                        <div className="input-2">
                            <input
                                name="last_name_affix"
                                value={formData.last_name_affix}
                                onChange={handleChange}
                                placeholder="Tussenvoegsel"
                            />
                            <input
                                required
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                placeholder="*Achternaam"
                            />
                            <input
                                type="date"
                                required
                                name="birthday"
                                value={
                                    formData.birthday
                                        ? formData.birthday.substr(0, 10)
                                        : null
                                }
                                onChange={handleChange}
                                className="date"
                                placeholder="*Geboortedatum"
                            />
                        </div>
                    </div>

                    <div className="form-ele-1">
                        <h4 className="heading">Adres/contact gegevens</h4>
                        <div className="input-1">
                            <input
                                required
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                                placeholder="*Straatnaam"
                            />
                            <input
                                required
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                placeholder="*Plaats"
                            />
                            <input
                                required
                                name="street_number"
                                value={formData.street_number}
                                onChange={handleChange}
                                placeholder="*Huisnummer"
                                style={{ width: "117px" }}
                                pattern="\d{0,20}"
                            />
                            <input
                                name="street_number_add_on"
                                value={formData.street_number_add_on}
                                onChange={handleChange}
                                placeholder="Toevoeging"
                                style={{ width: "117px" }}
                            />
                        </div>
                        <div className="input-2">
                            <input
                                required
                                name="postal_code"
                                value={formData.postal_code}
                                onChange={handleChange}
                                placeholder="*Postcode"
                            />
                            <input
                                required
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                placeholder="*Telefoonnummer"
                                pattern="\d{0,20}"
                            />
                            <input
                                required
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="*Email"
                                pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*"
                            />
                        </div>
                    </div>

                    <div className="form-ele-1">
                        <h4 className="heading">Bank/legitimatie gegevens</h4>
                        <div className="input-1">
                            <input
                                name="bank_account_number"
                                value={formData.bank_account_number}
                                onChange={handleChange}
                                placeholder="IBAN (NL)"
                            />
                            <input
                                name="name_on_bank_account"
                                value={formData.name_on_bank_account}
                                onChange={handleChange}
                                placeholder="Naam rekeninghouder"
                            />
                            <select
                                id="Selector"
                                name="identification_type"
                                value={formData.identification_type}
                                onChange={handleChange}
                            >
                                <option value="Selecteer type ID">
                                    Selecteer type ID
                                </option>
                                {identificationTypes.map((value) => (
                                    <option value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-2">
                            <input
                                name="identification_number"
                                value={formData.identification_number}
                                onChange={handleChange}
                                placeholder="Nummer"
                            />
                            <input
                                type="date"
                                name="identification_expiry_date"
                                value={
                                    formData.identification_expiry_date
                                        ? formData.identification_expiry_date.substr(
                                              0,
                                              10
                                          )
                                        : null
                                }
                                onChange={handleChange}
                                placeholder="Geldig tot"
                            />
                            <select
                                id="Selector"
                                name="identification_hand_out_country"
                                value={formData.identification_hand_out_country}
                                onChange={handleChange}
                            >
                                <option value="Land van afgifte">
                                    Land van afgifte
                                </option>
                                {countries.map((value) => (
                                    <option value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-2">
                            <input
                                style={{
                                    visbility: "hdden",
                                    borderBottom: "none",
                                }}
                            />
                            <input
                                style={{
                                    visbility: "hdden",
                                    borderBottom: "none",
                                }}
                            />
                            <select
                                id="Selector"
                                name="identification_origin"
                                value={formData.identification_origin}
                                onChange={handleChange}
                            >
                                <option value="">Land van Herkomst</option>
                                {countries.map((value) => (
                                    <option value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-btn">
                    <button className="btn" type="submit">
                        Opslaan
                    </button>
                    <button
                        className="btn reset-btn"
                        type="button"
                        onClick={() =>
                            setForm(getOriginalValues(location.state))
                        }
                    >
                        Reset
                    </button>
                </div>
            </form>
        </>
    );
};

export default EditForm;
