import React from "react";
import "./FormValue.css";
import Header from "../Components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import reformatDate from "../util/format";

const FormValue = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const formData = location.state;
    return (
        <>
            <Header />
            <div className="value-page" id="value-page">
                <div className="form-heading">
                    <span>
                        <i
                            className="fa-solid fa-arrow-left"
                            onClick={() => {
                                navigate(-1);
                            }}
                        ></i>
                    </span>
                    <h1>Klantenoverzicht</h1>
                </div>
                <hr style={{ marginTop: "60px", marginBottom: "0px" }} />
                <div className="value-element">
                    <div className="form-ele-1">
                        <h4 className="heading">Persoonsgegevens</h4>
                        <div className="value-1">
                            <p className="pb-0 mb-0">Geslacht:</p>
                            <p className="pb-0 mb-0">Voorletters:</p>
                            <p className="pb-0 mb-0">Voornaam:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">{formData.gender}</p>
                            <p className="pt-0 mt-0">{formData.initials}</p>
                            <p className="pt-0 mt-0">{formData.first_name}</p>
                        </div>
                        <div className="value-1">
                            <p className="pb-0 mb-0">Tussenvoegsel:</p>
                            <p className="pb-0 mb-0">Achternaam:</p>
                            <p className="pb-0 mb-0">Geboortedatum:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">
                                {formData.last_name_affix}
                            </p>
                            <p className="pt-0 mt-0">{formData.last_name}</p>
                            <p className="pt-0 mt-0">
                                {reformatDate(formData.birthday)}
                            </p>
                        </div>
                    </div>
                    <div className="form-ele-1">
                        <h4 className="heading">Adres/contact gegevens</h4>
                        <div className="value-1">
                            <p className="pb-0 mb-0">Straatnaam:</p>
                            <p className="pb-0 mb-0">Plaats:</p>
                            <p className="pb-0 mb-0" style={{ width: "117px" }}>
                                Huisnummer:
                            </p>
                            <p className="pb-0 mb-0" style={{ width: "117px" }}>
                                Toevoeging:
                            </p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">
                                {formData?.address?.street}
                            </p>
                            <p className="pt-0 mt-0">
                                {formData?.address?.city}
                            </p>
                            <p className="pt-0 mt-0" style={{ width: "117px" }}>
                                {formData?.address?.street_number}
                            </p>
                            <p className="pt-0 mt-0" style={{ width: "117px" }}>
                                {formData?.address?.street_number_add_on}
                            </p>
                        </div>
                        <div className="value-1">
                            <p className="pb-0 mb-0">PostCode:</p>
                            <p className="pb-0 mb-0">Telefoonnummer:</p>
                            <p className="pb-0 mb-0">E-mail:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">
                                {formData?.address?.postal_code}
                            </p>
                            <p className="pt-0 mt-0">{formData.phone_number}</p>
                            <p className="pt-0 mt-0">{formData.email}</p>
                        </div>
                    </div>
                    <div className="form-ele-1">
                        <h4 className="heading">Bank/legitimatie gegevens</h4>
                        <div className="value-1">
                            <p className="pb-0 mb-0">IBAN (NL):</p>
                            <p className="pb-0 mb-0">Naam rekeninghouder:</p>
                            <p className="pb-0 mb-0">Type ID:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">
                                {formData?.bank?.bank_account_number}
                            </p>
                            <p className="pt-0 mt-0">
                                {formData?.bank?.name_on_bank_account}
                            </p>
                            <p className="pt-0 mt-0">
                                {formData?.identity?.identification_type}
                            </p>
                        </div>
                        <div className="value-1">
                            <p className="pb-0 mb-0">Nummer:</p>
                            <p className="pb-0 mb-0">Geldig tot:</p>
                            <p className="pb-0 mb-0">Land van afgifte:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0">
                                {formData?.identity?.identification_number}
                            </p>
                            <p className="pt-0 mt-0">
                                {reformatDate(
                                    formData?.identity
                                        ?.identification_expiry_date
                                )}
                            </p>
                            <p className="pt-0 mt-0">
                                {
                                    formData?.identity
                                        ?.identification_hand_out_country
                                }
                            </p>
                        </div>
                        <div className="value-1">
                            <p className="pt-0 mt-0"></p>
                            <p className="pt-0 mt-0"></p>
                            <p className="pb-0 mb-0">Land van herkomst:</p>
                        </div>
                        <div className="value-2">
                            <p className="pt-0 mt-0"></p>
                            <p className="pt-0 mt-0"></p>
                            <p className="pt-0 mt-0">
                                {formData?.identity?.identification_origin}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="form-btn">
                    <button
                        className="btn"
                        onClick={() => {
                            if (formData.id) {
                                navigate("/edit", { state: formData });
                            } else {
                                navigate("/customers");
                            }
                        }}
                    >
                        Bewerken
                    </button>
                </div>
            </div>
        </>
    );
};

export default FormValue;
