import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    AuthFlowType,
    GetUserCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import React, { createContext, useEffect, useReducer } from "react";
import "./App.css";
import Login from "./pages/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import Listing from "./pages/Listing";
import Form from "./pages/Form";
import EditForm from "./pages/EditForm";
import FormValue from "./pages/FormValue";
import PrivateRoute from "./PrivateRoute";
import { somethingWentWrongDialog } from "./Components/dialog";

const store = createContext();
const { Provider } = store;
export { store };
const App = () => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(
        (currentState, action) => {
            switch (action.type) {
                case "UpdateUserName":
                    return {
                        ...currentState,
                        username: action.payload,
                    };
                case "UpdatePassword":
                    return {
                        ...currentState,
                        password: action.payload,
                    };
                case "OnSubmit":
                    onSubmit(currentState);
                    return {
                        ...currentState,
                        loading: true,
                    };
                case "StopLoading":
                    return {
                        ...currentState,
                        loading: false,
                    };
                default:
                    return currentState;
            }
        },
        {
            username: "",
            password: "",
            loading: false,
        }
    );

    async function onSubmit(state) {
        try {
            localStorage.clear();
            const cognitoClient = new CognitoIdentityProviderClient({
                region: process.env.REACT_APP_REGION,
            });
            const initiateAuthCommand = new InitiateAuthCommand({
                AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
                ClientId: process.env.REACT_APP_CLIENT_ID,
                AuthParameters: {
                    USERNAME: state.username, //'hjbkarl@gmail.com',
                    PASSWORD: state.password, //'Pass123!'
                },
            });
            const authResponse = await cognitoClient.send(initiateAuthCommand);
            localStorage.setItem(
                "IdToken",
                authResponse.AuthenticationResult?.IdToken
            );
            localStorage.setItem(
                "RefreshToken",
                authResponse.AuthenticationResult?.RefreshToken
            );
            localStorage.setItem(
                "AccessToken",
                authResponse.AuthenticationResult?.AccessToken
            );
            localStorage.setItem(
                "ExpiresIn",
                authResponse.AuthenticationResult?.ExpiresIn
            );

            let getUserResponse = await cognitoClient.send(
                new GetUserCommand({
                    AccessToken: authResponse.AuthenticationResult?.AccessToken,
                })
            );
            var fullName = "";
            getUserResponse.UserAttributes.forEach((item) => {
                if (item.Name === "given_name") {
                    fullName = `${item.Value} ` + fullName;
                }
                if (item.Name === "family_name") {
                    fullName = fullName + item.Value;
                }
            });
            localStorage.setItem("Username", getUserResponse.Username);
            localStorage.setItem("FullName", fullName);

            let now = new Date().getTime();
            localStorage.setItem("setupTime", now);
            dispatch({ type: "StopLoading" });
            navigate("customers");
        } catch (error) {
            somethingWentWrongDialog();
            dispatch({ type: "StopLoading" });
        }
    }

    useEffect(() => {
        let ExpiresIn = localStorage.getItem("ExpiresIn");
        if (ExpiresIn) {
            let time = parseInt(ExpiresIn); //milli seconds
            let now = new Date().getTime();
            let setupTime = localStorage.getItem("setupTime");
            if (setupTime == null) {
                localStorage.setItem("setupTime", now);
            } else {
                if (now - setupTime > time * 1000) {
                    localStorage.clear();
                }
            }
        }
    }, []);

    return (
        <Provider value={{ state, dispatch }}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/customers"
                    element={
                        <PrivateRoute>
                            <Listing />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/form"
                    element={
                        <PrivateRoute>
                            <Form />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/edit"
                    element={
                        <PrivateRoute>
                            <EditForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/value"
                    element={
                        <PrivateRoute>
                            <FormValue />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </Provider>
    );
};

export default App;
