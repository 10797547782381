import React from "react";
import "./Header.css";
import Navbar from "./Navbar";

const Header = () => {
    const toggleSidebar = () => {
        if (document.getElementById("list")) {
            document.getElementById("list").classList.toggle("sidebar-open");
            document
                .querySelector(".pagination")
                .classList.toggle("sidebar-open");
        }
        if (document.getElementById("form")) {
            document.getElementById("form").classList.toggle("sidebar-open");
        }
        if (document.getElementById("value-page")) {
            document
                .getElementById("value-page")
                .classList.toggle("sidebar-open");
        }
        document.getElementById("main").classList.toggle("sidebar-active");
    };
    return (
        <>
            <header>
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="ham-icon">
                    <img
                        src={require("../assets/img/Menu.png")}
                        alt=""
                        onClick={toggleSidebar}
                    />
                </div>
                <div className="admin">
                    <div className="">
                        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                            {localStorage.getItem("FullName")}
                        </p>
                        <p>{localStorage.getItem("Username")}</p>
                    </div>
                    <div className="admin-icon">
                        <img src={require("../assets/img/admin.png")} alt="" />
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
