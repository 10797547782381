import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import { getList } from "../service";
import reformatDate from "../util/format";
import "./Listing.css";
import "../Components/dialog";

const Listing = () => {
    const navigate = useNavigate();
    const [maxPage, setMaxPage] = useState(0);
    const [list, setList] = useState([]);
    const [active, setActive] = useState(1);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getList(active)
            .then((res) => {
                if (res.data.items && res.data.items.length) {
                    setList(res.data.items);
                    setMaxPage(res.data.max_page);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    }, [active]);

    var pagination = []
    
    if (maxPage < 10) { 
        pagination = Array.from({ length: maxPage }, (v, i) => i + 1)
    } else {
        if(active < 5){ 
            pagination = [1,2,3, 4, 5, "", "", "",maxPage]
        } else if(active > (maxPage - 4)){ 
            pagination = [1, "", "", "", maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage]
        } else {
            pagination = [1,"", active - 2, active - 1, active, active + 1, active + 2, "", 15]
        }
    }


    return (
        <>
            <Header />
            {loading ? (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999,
                        backgroundColor: "rgb(0,0,0,0.2)",
                    }}
                >
                    <div className="spinner-border"></div>
                </div>
            ) : null}
            <div className="list" id="list">
                <h1>Klantenoverzicht</h1>
                <div style={{ height: "63vh", overflowY: "auto" }}>
                    {list.length ? (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th key="header_0"></th>
                                    <th
                                        key="header_1"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Klant Nr.
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_2"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Voorletters
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_3"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Achternaam
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_4"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Telefoon
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_5"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Geboortedatum
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_6"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Aangemaakt op
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_7"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    >
                                        Gewijzigd op
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </th>
                                    <th
                                        key="header_8"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    />
                                    <th
                                        key="header_9"
                                        scope="col"
                                        style={{ width: "120px" }}
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((v) =>
                                    !v ? (
                                        <tr>
                                            <td
                                                key="value_0"
                                                style={{ height: "50px" }}
                                            />
                                        </tr>
                                    ) : (
                                        <tr
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                navigate("/value", {
                                                    state: v,
                                                });
                                            }}
                                        >
                                            <th
                                                key="{v.id}_value_0"
                                                scope="col"
                                                style={{ width: "100px" }}
                                            >
                                                <input
                                                    className="me-1"
                                                    type="radio"
                                                    name="listGroupRadio"
                                                    value=""
                                                    id="firstRadio"
                                                />
                                                <img
                                                    src={require("../assets/img/Profile.png")}
                                                    alt=""
                                                />
                                            </th>
                                            <td key="{v.id}_value_2">{v.id}</td>
                                            <td
                                                key="{v.id}_value_1"
                                                style={{ height: "50px" }}
                                            >
                                                {v.initials}
                                            </td>
                                            <td key="{v.lastname}_value_2">
                                                {v.last_name}
                                            </td>
                                            <td key="{v.phone_number}_value_3">
                                                {v.phone_number}
                                            </td>
                                            <td key="{v.birthday}_value_4">
                                                {reformatDate(v.birthday)}
                                            </td>
                                            <td key="{v.created_at}_value_5">
                                                {reformatDate(v.created_at)}
                                            </td>
                                            <td key="{v.updated_at}_value_6">
                                                {reformatDate(v.updated_at)}
                                            </td>
                                            <td key="{v.id}_value_7"></td>
                                            <td key="{v.id}_value_8"></td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    ) : null}
                </div>
                <div className="plus-btn">
                    <button
                        onClick={() => {
                            navigate("/form");
                        }}
                    >
                        <i className="far fa-plus"></i>
                    </button>
                </div>
                <div className="pagination">
                    {maxPage ? (
                        <a
                            href="/#"
                            className="arrow"
                            onClick={(e) => {
                                e.preventDefault();
                                setActive((act) =>
                                    act - 1 > 0 ? act - 1 : act
                                );
                            }}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </a>
                    ) : null}
                    {pagination.map(
                        (v) => {
                            if (v === "") {
                                return (<a>
                                    {"."}
                                </a>)
                            }
                            return (
                                <a
                                    href="/#"
                                    className={v === active ? "active" : ""}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActive(v);
                                    }}
                                >
                                    {v}
                                </a>
                            );
                        }
                    )}
                    {maxPage ? (
                        <a
                            href="/#"
                            className="arrow"
                            onClick={(e) => {
                                e.preventDefault();
                                setActive((act) =>
                                    act + 1 <= maxPage ? act + 1 : act
                                );
                            }}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </a>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Listing;
